@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root {
  --primary: #2596be;
  --secondary: #1a6885;
}
body {
  font-family: "Open Sans", sans-serif;
  overflow-x: hidden;
}
.container-fluid.auth-layout {
  padding-left: 100px !important;
  padding-right: 30px !important;
}

.MuiSnackbar-root {
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.25);
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
input:-moz-autofill,
input:-moz-autofill:hover,
input:-moz-autofill:focus,
input:-moz-autofill:active {
  box-shadow: 0 0 0 30px white inset !important;
}
.auth-layout .MuiTextField-root label {
  padding: 0 5px;
}
.auth-layout .MuiTextField-root label[data-shrink="true"] {
  -webkit-transform: translate(14px, -12px) scale(0.75) !important;
          transform: translate(14px, -12px) scale(0.75) !important;
  background-color: rgb(255, 255, 255) !important;
}
.auth-layout .MuiTextField-root input {
  padding-left: 20px !important;
}

.auth-layout .MuiTextField-root textarea {
  padding-left: 5px !important;
}

.MuiFormControlLabel-root span:nth-child(2) {
  color: rgba(0, 0, 0, 0.6) !important;
}
.badge-primary {
  background-color: #2596be;
  background-color: var(--primary);
  color: white;
}
.heading h3 {
  font-weight: 600;
  font-size: 1.9rem;
}
@media (max-width: 992px) {
  .heading h3 {
    font-size: 1.8rem;
  }
}
@media (max-width: 767px) {
  .heading h3 {
    font-size: 1.7rem;
  }
}

[data-simplebar] {
  position: relative;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
}
.simplebar-wrapper {
  overflow: hidden;
  width: inherit;
  height: inherit;
  max-width: inherit;
  max-height: inherit;
}
.simplebar-mask {
  direction: inherit;
  position: absolute;
  overflow: hidden;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: auto !important;
  height: auto !important;
  z-index: 0;
}
.simplebar-offset {
  direction: inherit !important;
  box-sizing: inherit !important;
  resize: none !important;
  position: absolute;
  top: 0;
  left: 0 !important;
  bottom: 0;
  right: 0 !important;
  padding: 0;
  margin: 0;
  -webkit-overflow-scrolling: touch;
}
.simplebar-content-wrapper {
  direction: inherit;
  box-sizing: border-box !important;
  position: relative;
  display: block;
  height: 100%;
  /* Required for horizontal native scrollbar to not appear if parent is taller than natural height */
  width: auto;
  visibility: visible;
  overflow: auto;
  /* Scroll on this element otherwise element can't have a padding applied properly */
  max-width: 100%;
  /* Not required for horizontal scroll to trigger */
  max-height: 100%;
  /* Needed for vertical scroll to trigger */
  scrollbar-width: none;
  padding: 0px !important;
}
.simplebar-content-wrapper::-webkit-scrollbar,
.simplebar-hide-scrollbar::-webkit-scrollbar {
  display: none;
}
.simplebar-content:before,
.simplebar-content:after {
  content: " ";
  display: table;
}
.simplebar-placeholder {
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  pointer-events: none;
}
.simplebar-height-auto-observer-wrapper {
  box-sizing: inherit !important;
  height: 100%;
  width: 100%;
  max-width: 1px;
  position: relative;
  float: left;
  max-height: 1px;
  overflow: hidden;
  z-index: -1;
  padding: 0;
  margin: 0;
  pointer-events: none;
  flex-grow: inherit;
  flex-shrink: 0;
  flex-basis: 0;
}
.simplebar-height-auto-observer {
  box-sizing: inherit;
  display: block;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 1000%;
  width: 1000%;
  min-height: 1px;
  min-width: 1px;
  overflow: hidden;
  pointer-events: none;
  z-index: -1;
}
.simplebar-track {
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden;
}
[data-simplebar].simplebar-dragging .simplebar-content {
  pointer-events: none;
  user-select: none;
  -webkit-user-select: none;
}
[data-simplebar].simplebar-dragging .simplebar-track {
  pointer-events: all;
}
.simplebar-scrollbar {
  position: absolute;
  width: 10px;
  min-height: 10px;
}
.simplebar-scrollbar:before {
  position: absolute;
  content: "";
  /* background: #a2adb7; */
  border-radius: 100px;
  left: 0;
  right: 0;
  opacity: 0;
  transition: opacity 0.2s linear;
}
.simplebar-scrollbar.simplebar-visible:before {
  /* When hovered, remove all transitions from drag handle */
  opacity: 0.2 !important;
  transition: opacity 0s linear;
}
.simplebar-track.simplebar-vertical {
  top: 0;
  width: 11px;
}
.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  top: 2px;
  bottom: 2px;
}
.simplebar-track.simplebar-horizontal {
  left: 0;
  height: 11px;
}
.simplebar-track.simplebar-horizontal .simplebar-scrollbar:before {
  height: 100%;
  left: 4px;
  right: 0px;
}
.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
  right: auto;
  left: 0;
  top: 2px;
  height: 7px;
  min-height: 0;
  min-width: 10px;
  width: auto;
}
/* Rtl support */
[data-simplebar-direction="rtl"] .simplebar-track.simplebar-vertical {
  right: auto;
  left: 0;
}
.hs-dummy-scrollbar-size {
  direction: rtl;
  position: fixed;
  opacity: 0;
  visibility: hidden;
  height: 500px;
  width: 500px;
  overflow-y: hidden;
  overflow-x: scroll;
}
.simplebar-hide-scrollbar {
  position: fixed;
  left: 0;
  visibility: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
}
.custom-scroll {
  height: 100%;
}

.sidebar {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 78px;
  background: var(--primary);
  padding: 6px 14px;
  z-index: 99;
  transition: all 0.5s ease;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
.sidebar.open {
  width: 250px;
}

.sidebar .logo-details {
  height: 60px;
  display: flex;
  align-items: center;
  position: relative;
}
.sidebar .logo-details .icon {
  opacity: 0;
  transition: all 0.5s ease;
}
.sidebar .logo-details img {
  opacity: 0;
  height: 35px;
  position: relative;
  top: 5px;
}
.sidebar .logo-details .logo_name {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  opacity: 0;
  transition: all 0.5s ease;
  padding-left: 10px;
  line-height: 20px;
  margin-top: 10px;
}
.sidebar .logo-details .logo_name::after {
  content: "v1.0.5";
  font-size: 9px;
  display: block;
}
.sidebar.open .logo-details .icon,
.sidebar.open .logo-details img,
.sidebar.open .logo-details .logo_name {
  opacity: 1;
}
.sidebar .logo-details #btn {
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  font-size: 22px;
  transition: all 0.4s ease;
  font-size: 23px;
  text-align: center;
  cursor: pointer;
  transition: all 0.5s ease;
}
.sidebar.open .logo-details #btn {
  text-align: right;
}
.sidebar i {
  color: #fff;
  height: 60px;
  min-width: 50px;
  font-size: 28px;
  text-align: center;
  line-height: 60px;
}
.sidebar .nav-list {
  margin-top: 20px;
  height: 100%;
  padding: 0 !important;
}
.sidebar li {
  position: relative;
  margin: 8px 0;
  list-style: none;
}
.sidebar li .tooltip {
  position: absolute;
  top: -20px;
  left: calc(100% + 15px);
  z-index: 3;
  background: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 400;
  opacity: 0;
  white-space: nowrap;
  pointer-events: none;
  transition: 0s;
}
.sidebar li:hover .tooltip {
  opacity: 1;
  pointer-events: auto;
  transition: all 0.4s ease;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.sidebar.open li .tooltip {
  display: none;
}

.sidebar li a {
  display: flex;
  height: 100%;
  width: 100%;
  border-radius: 12px;
  align-items: center;
  text-decoration: none;
  transition: all 0.4s ease;
  background: var(--primary);
}
.sidebar li a.active {
  background-color: var(--secondary);
}
.sidebar li a:hover {
  background: #fff;
}
.sidebar li a .links_name {
  color: #fff;
  font-size: 15px;
  font-weight: 400;
  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
  transition: 0.4s;
}
.sidebar.open li a .links_name {
  opacity: 1;
  pointer-events: auto;
}
.sidebar li a:hover .links_name,
.sidebar li a:hover i {
  transition: all 0.5s ease;
  color: #11101d;
}
.sidebar li i {
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  border-radius: 12px;
}
.sidebar li.profile {
  position: fixed;
  height: 60px;
  width: 78px;
  left: 0;
  bottom: -8px;
  padding: 10px 14px;
  background: var(--secondary);
  transition: all 0.5s ease;
  overflow: hidden;
}
.sidebar.open li.profile {
  width: 250px;
}
.sidebar li .profile-details {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}
.sidebar li img {
  height: 40px;
  width: 40px;
  object-fit: cover;
  border-radius: 100%;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.15);
}
.sidebar li.profile .name_job {
  margin-left: 10px;
  /* display: none; */
}
.sidebar li.profile .name,
.sidebar li.profile .job {
  font-size: 15px;
  font-weight: 400;
  color: #fff;
  white-space: nowrap;
}
.sidebar li.profile .job {
  font-size: 12px;
}
.sidebar .profile #log_out {
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background: var(--secondary);
  width: 100%;
  height: 60px;
  line-height: 60px;
  border-radius: 0px;
  transition: all 0.5s ease;
}
.sidebar.open .profile #log_out {
  width: 50px;
  background: none;
}
.home-section {
  position: relative;
  background: #e4e9f7;
  min-height: 100vh;
  top: 0;
  left: 78px;
  width: calc(100% - 78px);
  transition: all 0.5s ease;
  z-index: 2;
}
.sidebar.open ~ .home-section {
  left: 250px;
  width: calc(100% - 250px);
}
.home-section .text {
  display: inline-block;
  color: #11101d;
  font-size: 25px;
  font-weight: 500;
  margin: 18px;
}
@media (max-width: 420px) {
  .sidebar li .tooltip {
    display: none;
  }
}
@media (max-width: 500px) {
  .sidebar {
    width: 60px;
    padding: 6px 8px;
  }
  .sidebar i {
    min-width: 45px;
  }
  .sidebar li.profile {
    width: 60px;
  }
  .sidebar.open {
    padding: 6px 14px;
  }
  .container-fluid.auth-layout {
    padding-left: 70px !important;
    padding-right: 15px !important;
  }
}

.Error {
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.content {
  width: 40%;
}
.content h6 {
  font-weight: 600;
  font-size: 1.5rem;
  line-clamp: 1;
}
.content h1 {
  color: var(--primary);
  font-weight: 900;
  font-size: 3rem;
}
.content button {
  background-color: var(--primary) !important;
  border: none;
  outline: none;
  box-shadow: none !important;
}
.max-z-index {
  z-index: 21474890003;
}
@media (max-width: 767px) {
  .Error {
    flex-direction: column-reverse;
    grid-gap: 20px;
    gap: 20px;
  }
  .content {
    width: 80%;
  }
}
@media (max-width: 500px) {
  .Error img {
    width: 250px;
    height: 200px;
  }
}

.bg-overlay {
  position: relative;
  background-color: var(--primary);
  min-height: 100vh;
}
.bg-overlay img {
  height: 400px;
  width: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.auth-logo {
  width: 100px !important;
  height: 105px !important;
}
.auth-form-container.login,
.auth-form-container.forgot-password {
  padding: 50px 35px 0px 30px;
}
.auth-form-container.signup {
  padding: 30px 35px 0px 30px;
}

.auth-button,
.auth-button:active,
.auth-button:focus {
  background-color: var(--primary) !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}
.auth-button {
  width: 100%;
  border-radius: 100px !important;
  padding: 15px 0 !important;
  margin-top: 10px;
  font-size: 1rem !important;
  font-weight: 600;
  transition: background-color 0.3s;
}
.auth-button:hover {
  background-color: #2187ac !important;
}
.bottom-text {
  margin-top: 10px;
}
.bottom-text a {
  color: var(--primary);
  text-decoration: none;
  font-weight: 600;
}
#or {
  position: relative;
  margin-top: 15px;
  margin-bottom: 25px;
}
#or .line {
  width: 100%;
  height: 1px;
  background-color: rgb(212, 212, 212);
  position: absolute;
  top: 14px;
  z-index: -1;
}
#or span {
  padding: 10px;
  background-color: white;
  font-size: 0.9rem;
  color: rgb(212, 212, 212);
  font-weight: 600;
}
.change-email {
  text-align: right;
  font-size: 0.9rem;
  font-weight: 600;
  color: var(--primary);
  cursor: pointer;
}

/* Media queries  */
@media (max-width: 767px) {
  .bg-overlay {
    display: none;
  }
}

.auth-form {
  padding: 20px 10px 10px 10px;
}
.auth-form h2 {
  font-weight: 600;
}

.google-signin {
  position: relative;
  margin: 10px 0;
}

.google-oauth {
  border: none;
  outline: none;
  padding: 15px;
  border-radius: 30px;
  font-weight: 600;
  width: 100%;
  background-color: transparent;
  border: 1px solid rgb(212, 212, 212);
  transition: background-color 0.3s;
}
.google-oauth:hover {
  border: 1px solid rgb(165, 164, 164);
}
.google-oauth img {
  width: 20px;
  height: 20px;
  position: relative;
  top: -2px;
}

.login-form,
.myspace-form {
  margin-top: 15px;
  position: relative;
}
.auth-form .MuiOutlinedInput-root {
  border-radius: 100px !important;
}

.forgot {
  text-align: right;
  margin-bottom: 22px;
}
.forgot-pass {
  text-decoration: none;
  text-align: right;
  font-size: 0.9rem;
  font-weight: 600;
  color: var(--primary);
}

.auth label {
  padding: 0 5px;
}
.auth label[data-shrink="true"] {
  -webkit-transform: translate(14px, -12px) scale(0.75) !important;
          transform: translate(14px, -12px) scale(0.75) !important;
  background-color: rgb(255, 255, 255) !important;
}
.auth input {
  padding-left: 20px !important;
}

.profile-box .card,
.create-event .card,
.activity-widget .card,
.upcoming-events .card,
.view-tasks .card {
  overflow: hidden;
  background-color: rgb(247, 247, 247);
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  border: none;
  max-height: 450px;
}
.modalProfile {
  padding-top: 10px;
}
.modalProfile span {
  height: 60px !important;
  width: 60px !important;
}
.modalProfile .listIcon {
  font-size: 19px;
  position: relative;
  top: -1px;
  left: -1px;
}

.my-space-card-title {
  font-weight: 600;
  font-size: 1.5rem;
}
.profile-box,
.activity-widget,
.upcoming-events,
.view-tasks {
  height: 100% !important;
  /* min-height: 100%; */
}
.profile-box .card {
  padding: 1em 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.profile-box .edit-profile {
  position: absolute;
  top: 10px;
  right: 10px;
}
.profile-box .view-profile {
  position: absolute;
  top: 10px;
  left: 10px;
}
.profile-image {
  display: flex;
  justify-content: center;
}
.profileBoxImg div {
  height: 150px;
  width: 150px;
  transition: all 0.2s;
}
@media (max-width: 600px) {
  .profileBoxImg div {
    height: 120px;
    width: 120px;
  }
}
@media (max-width: 440px) {
  .profileBoxImg div {
    height: 100px;
    width: 100px;
    /* margin-top: 20px; */
  }
  .profile-box .edit-profile {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
    top: 7px;
    right: 3px;
  }
  .profile-box .view-profile {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
    top: 7px;
    left: 3px;
  }
}
.skills,
.socialLinks {
  padding: 10px;
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  justify-content: center;
  flex-wrap: wrap;
}
.socialLinks {
  grid-gap: 25px;
  gap: 25px;
}
.socialLinks a {
  text-decoration: none;
}
.socialLinks svg {
  color: var(--primary);
  cursor: pointer;
}

.form-buttons {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  margin-top: 20px;
}
/* .reset-form {
  background-color: grey !important;
} */
.form-buttons button {
  width: 50%;
}
@media (max-width: 1405px) and (min-width: 1200px) {
  .form-buttons {
    flex-direction: column;
  }

  .form-buttons button {
    width: 100%;
  }
}
@media (max-width: 992px) {
  .form-buttons {
    flex-direction: column;
  }

  .form-buttons button {
    width: 100%;
  }
}

.activity-wid {
  margin-top: 15px;
  margin-left: 16px;
}

.activity-list {
  position: relative;
  padding: 0 0 40px 30px;
}
.upcoming-events .activity-list,
.view-tasks .activity-list {
  cursor: pointer;
  padding: 0 10px 30px 15px;
}
.upcoming-events .event-details,
.view-tasks .task-details {
  background: white;
  padding: 8px 15px 10px;
  border-radius: 10px;
  box-shadow: inset -1px -1px 3px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.5s;
}
.upcoming-events .event-details:hover,
.view-tasks .event-details:hover {
  box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
}

.activity-list:before {
  content: "";
  border-left: 2px dashed rgba(0, 0, 0, 0.25);
  position: absolute;
  left: -5px;
  bottom: 0;
  top: 28px;
}
.activity-icon {
  position: absolute;
  left: -15.5px;
  top: 0;
  z-index: 9;
  color: var(--primary);
}
.font-size-10 {
  font-size: 1.1rem;
}
.activity-wid:last-child {
  padding-bottom: 0px;
}

.create-event .MuiFormControlLabel-root:nth-child(1) {
  margin-right: 0 !important;
}
.create-event .help-icon {
  cursor: pointer;
  position: relative;
  top: 6px;
  left: 2px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 1rem;
}

.create-event .MuiTextField-root label[data-shrink="true"],
#event-type[data-shrink="true"],
#domain-label[data-shrink="true"] {
  -webkit-transform: translate(14px, -12px) scale(0.75) !important;
          transform: translate(14px, -12px) scale(0.75) !important;
  background-color: rgb(247, 247, 247) !important;
  padding: 0 5px;
}

.drawer,
.giftIcon {
  background-color: var(--primary) !important;
  color: white !important;
  /* transform: translateX(50%); */
}
.giftIcon {
  padding: 8px;
  border-radius: 100%;
  cursor: pointer;
}
.text-overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.viewAll button {
  background: var(--primary) !important;
  border: none;
  outline: none;
}
.heading .MuiBadge-dot {
  position: absolute;
  top: 5px;
  right: 6px;
}
.optionContainer {
  max-height: 150px !important;
  scrollbar-color: grey;
  scrollbar-width: thin;
}
.MuiDrawer-paper.MuiDrawer-paperAnchorRight {
  overflow-x: hidden !important;
}
.overflow-hide-drawer.events-drawer {
  max-height: 45%;
  /* min-height: 50%; */
  overflow-y: scroll;
  scrollbar-color: rgb(59, 59, 59);
  scrollbar-width: thin;
}
.overflow-hide-drawer.tasks-drawer {
  max-height: 45%;
  /* min-height: 50%; */
  overflow-y: scroll;
  scrollbar-color: rgb(59, 59, 59);
  scrollbar-width: thin;
}
.highlightOption {
  background: transparent !important;
  color: var(--primary) !important;
}
.optionListContainer li:hover {
  background: rgba(0, 0, 0, 0.1) !important;
  color: var(--primary) !important;
}
input.searchBox::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 1);
}
input.searchBox::placeholder {
  color: rgba(0, 0, 0, 1);
}
.multiSelectContainer input#search_input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.6) !important;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
}
.multiSelectContainer input#search_input::placeholder {
  color: rgba(0, 0, 0, 0.6) !important;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
}
.multiSelectContainer input {
  margin: 3px 0;
}
.custom-divider {
  position: relative;
  margin: 10px 10px;
  height: 2px;
  background-color: rgba(0, 0, 0, 0.2);
}
.custom-divider::before {
  content: "";
  position: absolute;
  height: 15px;
  width: 15px;
  border-radius: 100%;
  background-color: #ccc;
  border: 5px solid white;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
@media (max-width: 767px) {
  .profile-box .name h3 {
    font-size: 1.3rem;
  }
  .profile-box .period h5 {
    font-size: 1.1rem;
  }
}

.event .card {
  overflow: hidden;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  border: none;
  height: 100%;
  /* max-height: ; */
}
.event .card-body {
  background-color: #90d9f7;
}
.event-heading .season {
  font-size: 0.7rem;
  margin-top: 3px;
}
.Event .card-footer {
  padding: 1.3em;
}
.event {
  height: 100% !important;
  cursor: pointer;
}
.Event .text-primary {
  color: var(--secondary) !important;
}
.event-heading .timer {
  position: relative;
  right: 4px;
  top: -1px;
  font-size: 13px;
}
.w-100 .timer {
  position: relative;
  right: 4px;
  top: 0px;
  font-size: 13px;
}
.w-100 .timer + span {
  font-size: 13px;
}
.event-footer .owner {
  font-size: 0.9rem;
}

.Event .description {
  max-height: 100px;
  min-height: 70px;
}
.eventModalOpen {
  width: 500px;
}
@media (max-width: 550px) {
  .eventModalOpen {
    width: 450px;
  }
}

@media (max-width: 475px) {
  .eventModalOpen {
    width: 400px;
  }
}
@media (max-width: 410px) {
  .eventModalOpen {
    width: 340px;
  }
}

@media (max-width: 1065px) {
  .hide_mobile {
    display: none !important;
  }
}
@media (min-width: 1065px) {
  .hide_screen {
    display: none !important;
  }
}
.hide_screen {
  position: relative;
  top: -5px;
}

.bg {
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-style: italic;
}

.searchUser input {
  padding-left: 5px !important;
}
.text-overflow {
  position: relative;
  overflow: hidden;
}
.text-overflow small {
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
}
.electron .name {
  /* display: block; */
  font-size: 1.3rem;
  line-height: 1.3rem;
}
.electron small {
  opacity: 0.8;
}
.electronImage div {
  width: 70px;
  height: 70px;
  transition: width 0.2s, height 0.2s;
}
@media (max-width: 794px) and (min-width: 620px) {
  .electronDetails {
    padding-left: 20px;
  }
}
@media (max-width: 619px) and (min-width: 575px) {
  .electronDetails {
    padding-left: 30px;
  }
}
@media (max-width: 440px) {
  .electronImage div {
    width: 60px;
    height: 60px;
    position: relative;
    top: 5px;
  }
}

.divider {
  min-height: 80%;
  border: 1px solid var(--primary);
  margin: auto 10px;
}

.member__socials a,
.domain {
  color: var(--primary);
  line-height: 1.1rem;
}
.member__card {
  min-height: 100%;
  position: relative;
}

.member__card {
  position: relative;
  background-color: rgb(247, 247, 247);
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  border: none;
  overflow: hidden;
}

.member__domains > div {
  padding: 5px;
  border: solid 1px var(--primary);
  border-radius: 20px;
  font-size: 12px;
}

.member__socials > * {
  cursor: pointer;
}
.member__card .period {
  color: var(--primary);
}
/* .mentor_image img {
  width: 400px;
  height: 350px;
} */

.domain__image {
  /* flex: 0.3; */
  /* width: clamp(300px, 100%, 500px); */
  height: 100%;
}
.domain__card {
  /* background-color: rgb(247, 247, 247); */
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  border: none;
}

@media (min-width: 1600px) {
  .domain__card {
    width: 90%;
    margin: 0 auto;
  }
}

.domain__image > img {
  width: 100%;
  object-fit: contain;
  height: auto;
  max-width: 450px;
  max-height: 280px;
}

.domain__content h4 {
  width: 80%;
  font-size: 1rem;
}

.domain__cta {
  width: 100%;
}
.colorPrimary {
  color: var(--primary);
}
.domain__socials > * {
  margin-right: 8px !important;
  cursor: pointer;
}
.domain__name {
  font-weight: 600;
  /* text-align: center; */
}
@media (max-width: 767px) {
  .domain__info {
    text-align: center;
  }
  .domain__lead {
    justify-content: center;
  }
  .domain__content h4 {
    font-size: 0.9rem;
  }
}

button[aria-selected="true"] {
  background-color: rgba(0, 0, 0, 0.05);
  color: var(--primary);
  border-radius: 5px;
}
.details-heading {
  font-size: 1.5rem;
  line-height: 1.5rem;
  font-weight: 500;
}
.deleteAccount {
  font-size: 0.9rem;
}
.college {
  position: relative;
}
.MuiFormControl-root label[data-shrink="true"] {
  -webkit-transform: translate(14px, -12px) scale(0.75) !important;
          transform: translate(14px, -12px) scale(0.75) !important;
  background-color: rgb(255, 255, 255) !important;
  padding: 0 5px;
}
.info-social,
.info-social svg {
  font-size: 12px;
  color: var(--primary);
}
.info-social svg {
  position: relative;
  top: -1px;
  margin-right: 2px;
}

@media (max-width: 1000px) {
  .container {
    max-width: 100% !important;
  }
}
@media (max-width: 660px) {
  .my-details-form .MuiTabs-scroller {
    overflow-x: scroll !important;
    scrollbar-color: rgb(59, 59, 59);
    scrollbar-width: thin;
  }
}

@media (max-width: 591px) {
  .markButton {
    font-size: 12px !important;
  }
}
.assignor-name {
  color: var(--primary);
  font-size: 0.85rem;
}

.profile-box .card,
.create-event .card,
.activity-widget .card,
.upcoming-events .card,
.view-tasks .card {
  overflow: hidden;
  background-color: rgb(247, 247, 247);
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  border: none;
  max-height: -webkit-fit-content;
  max-height: -moz-fit-content;
  max-height: fit-content;
}
.modalProfile {
  padding-top: 10px;
}
.modalProfile span {
  height: 60px !important;
  width: 60px !important;
}
.modalProfile .listIcon {
  font-size: 19px;
  position: relative;
  top: -1px;
  left: -1px;
}

.my-space-card-title {
  font-weight: 600;
  font-size: 1.5rem;
}
.profile-box,
.activity-widget,
.upcoming-events,
.view-tasks {
  height: 100% !important;
  /* min-height: 100%; */
}
.profile-box .card {
  padding: 1em 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.profile-box .edit-profile {
  position: absolute;
  top: 10px;
  right: 10px;
}
.profile-image {
  display: flex;
  justify-content: center;
}
.profileBoxImg div {
  height: 150px;
  width: 150px;
  transition: all 0.2s;
}
@media (max-width: 600px) {
  .profileBoxImg div {
    height: 120px;
    width: 120px;
  }
}
@media (max-width: 440px) {
  .profileBoxImg div {
    height: 100px;
    width: 100px;
    /* margin-top: 20px; */
  }
  .profile-box .edit-profile {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
    top: 7px;
    right: 3px;
  }
}
.skills,
.socialLinks {
  padding: 10px;
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  justify-content: center;
  flex-wrap: wrap;
}
.socialLinks {
  grid-gap: 25px;
  gap: 25px;
}
.socialLinks a {
  text-decoration: none;
}
.socialLinks svg {
  color: var(--primary);
  cursor: pointer;
}

.form-buttons {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  margin-top: 20px;
}
/* .reset-form {
  background-color: grey !important;
} */
.form-buttons button {
  width: 50%;
}
@media (max-width: 1405px) and (min-width: 1200px) {
  .form-buttons {
    flex-direction: column;
  }

  .form-buttons button {
    width: 100%;
  }
}
@media (max-width: 992px) {
  .form-buttons {
    flex-direction: column;
  }

  .form-buttons button {
    width: 100%;
  }
}

.activity-wid {
  margin-top: 15px;
  margin-left: 16px;
}

.activity-list {
  position: relative;
  padding: 0 0 40px 30px;
}
.upcoming-events .activity-list,
.view-tasks .activity-list {
  cursor: pointer;
  padding: 0 10px 30px 15px;
}
.upcoming-events .event-details,
.view-tasks .task-details {
  background: white;
  padding: 8px 15px 10px;
  border-radius: 10px;
  box-shadow: inset -1px -1px 3px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.5s;
}
.upcoming-events .event-details:hover,
.view-tasks .event-details:hover {
  box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
}

.activity-list:before {
  content: "";
  border-left: 2px dashed rgba(0, 0, 0, 0.25);
  position: absolute;
  left: -5px;
  bottom: 0;
  top: 28px;
}
.activity-icon {
  position: absolute;
  left: -15.5px;
  top: 0;
  z-index: 9;
  color: var(--primary);
}
.font-size-10 {
  font-size: 1.1rem;
}
.activity-wid:last-child {
  padding-bottom: 0px;
}

.create-event .MuiFormControlLabel-root:nth-child(1) {
  margin-right: 0 !important;
}
.create-event .help-icon {
  cursor: pointer;
  position: relative;
  top: 6px;
  left: 2px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 1rem;
}

.create-event .MuiTextField-root label[data-shrink="true"],
#event-type[data-shrink="true"] {
  -webkit-transform: translate(14px, -12px) scale(0.75) !important;
          transform: translate(14px, -12px) scale(0.75) !important;
  background-color: rgb(247, 247, 247) !important;
  padding: 0 5px;
}

.drawer,
.giftIcon {
  background-color: var(--primary) !important;
  color: white !important;
  /* transform: translateX(50%); */
}
.giftIcon {
  padding: 8px;
  border-radius: 100%;
  cursor: pointer;
}
.text-overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.viewAll button {
  background: var(--primary) !important;
  border: none;
  outline: none;
}
.heading .MuiBadge-dot {
  position: absolute;
  top: 5px;
  right: 6px;
}
.optionContainer {
  max-height: 150px !important;
  scrollbar-color: grey;
  scrollbar-width: thin;
}
.MuiDrawer-paper.MuiDrawer-paperAnchorRight {
  overflow-x: hidden !important;
}
.overflow-hide-drawer.events-drawer {
  max-height: 45%;
  /* min-height: 50%; */
  overflow-y: scroll;
  scrollbar-color: rgb(59, 59, 59);
  scrollbar-width: thin;
}
.overflow-hide-drawer.tasks-drawer {
  max-height: 45%;
  /* min-height: 50%; */
  overflow-y: scroll;
  scrollbar-color: rgb(59, 59, 59);
  scrollbar-width: thin;
}
.highlightOption {
  background: transparent !important;
  color: var(--primary) !important;
}
.optionListContainer li:hover {
  background: rgba(0, 0, 0, 0.1) !important;
  color: var(--primary) !important;
}
input.searchBox::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 1);
}
input.searchBox::placeholder {
  color: rgba(0, 0, 0, 1);
}
.date-joined {
  font-size: small;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 8px 0;
  margin-top: 16px;
  line-height: 0;
}
.date-joined-text {
  font-size: x-small;
}
.sub-title {
  font-size: medium;
  text-align: left;
}
@media (max-width: 767px) {
  .profile-box .name h3 {
    font-size: 1.3rem;
  }
  .profile-box .period h5 {
    font-size: 1.1rem;
  }
}

.techstacks-box .card,
.skills-box .card,
.contact-box .card,
.college-box .card {
	overflow: hidden;
	background-color: rgb(247, 247, 247);
	box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
	border: none;
	max-height: -webkit-fit-content;
	max-height: -moz-fit-content;
	max-height: fit-content;
	width: min(400px, 90%);
}

.techstacks-box,
.skills-box,
.contact-box,
.college-box {
	padding: 1em 0;
	display: flex;
	align-items: center;
	justify-content: center;
}

.socialLinks > a {
	display: flex;
	align-items: center;
	justify-content: space-between;
	/* margin: 0 auto; */
}

.socialLinks p {
	margin: auto;
	margin-left: 8px;
	color: #2596be;
}

.college-details {
	color: #2596be;
}

.share-icon-tooltip {
	position: fixed;
	bottom: 1em;
	right: 1em;
}

.share-icon {
	background: #2596be;
	color: #ffffff !important;
	border-radius: 50%;
	padding: 0.2em;
	cursor: pointer;
}

.share-icon:hover {
	-webkit-transform: scale(1.2);
	        transform: scale(1.2);
}


.domain__image {
  /* flex: 0.3; */
  /* width: clamp(300px, 100%, 500px); */
  height: 100%;
}
.domain__card {
  /* background-color: rgb(247, 247, 247); */
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  border: none;
}

@media (min-width: 1600px) {
  .domain__card {
    width: 90%;
    margin: 0 auto;
  }
}

.domain__image > img {
  width: 100%;
  object-fit: contain;
  height: auto;
  max-width: 450px;
  max-height: 280px;
}

.domain__content h4 {
  width: 80%;
  font-size: 1rem;
}

.domain__cta {
  width: 100%;
}
.colorPrimary {
  color: var(--primary);
}
.domain__socials > * {
  margin-right: 8px !important;
  cursor: pointer;
}
.domain__name {
  font-weight: 600;
  /* text-align: center; */
}
@media (max-width: 767px) {
  .domain__info {
    text-align: center;
  }
  .domain__lead {
    justify-content: center;
  }
  .domain__content h4 {
    font-size: 0.9rem;
  }
}

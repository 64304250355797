.profile-box .card,
.create-event .card,
.activity-widget .card,
.upcoming-events .card,
.view-tasks .card {
  overflow: hidden;
  background-color: rgb(247, 247, 247);
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  border: none;
  max-height: fit-content;
}
.modalProfile {
  padding-top: 10px;
}
.modalProfile span {
  height: 60px !important;
  width: 60px !important;
}
.modalProfile .listIcon {
  font-size: 19px;
  position: relative;
  top: -1px;
  left: -1px;
}

.my-space-card-title {
  font-weight: 600;
  font-size: 1.5rem;
}
.profile-box,
.activity-widget,
.upcoming-events,
.view-tasks {
  height: 100% !important;
  /* min-height: 100%; */
}
.profile-box .card {
  padding: 1em 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.profile-box .edit-profile {
  position: absolute;
  top: 10px;
  right: 10px;
}
.profile-image {
  display: flex;
  justify-content: center;
}
.profileBoxImg div {
  height: 150px;
  width: 150px;
  transition: all 0.2s;
}
@media (max-width: 600px) {
  .profileBoxImg div {
    height: 120px;
    width: 120px;
  }
}
@media (max-width: 440px) {
  .profileBoxImg div {
    height: 100px;
    width: 100px;
    /* margin-top: 20px; */
  }
  .profile-box .edit-profile {
    transform: scale(0.9);
    top: 7px;
    right: 3px;
  }
}
.skills,
.socialLinks {
  padding: 10px;
  display: flex;
  gap: 10px;
  justify-content: center;
  flex-wrap: wrap;
}
.socialLinks {
  gap: 25px;
}
.socialLinks a {
  text-decoration: none;
}
.socialLinks svg {
  color: var(--primary);
  cursor: pointer;
}

.form-buttons {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}
/* .reset-form {
  background-color: grey !important;
} */
.form-buttons button {
  width: 50%;
}
@media (max-width: 1405px) and (min-width: 1200px) {
  .form-buttons {
    flex-direction: column;
  }

  .form-buttons button {
    width: 100%;
  }
}
@media (max-width: 992px) {
  .form-buttons {
    flex-direction: column;
  }

  .form-buttons button {
    width: 100%;
  }
}

.activity-wid {
  margin-top: 15px;
  margin-left: 16px;
}

.activity-list {
  position: relative;
  padding: 0 0 40px 30px;
}
.upcoming-events .activity-list,
.view-tasks .activity-list {
  cursor: pointer;
  padding: 0 10px 30px 15px;
}
.upcoming-events .event-details,
.view-tasks .task-details {
  background: white;
  padding: 8px 15px 10px;
  border-radius: 10px;
  box-shadow: inset -1px -1px 3px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.5s;
}
.upcoming-events .event-details:hover,
.view-tasks .event-details:hover {
  box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
}

.activity-list:before {
  content: "";
  border-left: 2px dashed rgba(0, 0, 0, 0.25);
  position: absolute;
  left: -5px;
  bottom: 0;
  top: 28px;
}
.activity-icon {
  position: absolute;
  left: -15.5px;
  top: 0;
  z-index: 9;
  color: var(--primary);
}
.font-size-10 {
  font-size: 1.1rem;
}
.activity-wid:last-child {
  padding-bottom: 0px;
}

.create-event .MuiFormControlLabel-root:nth-child(1) {
  margin-right: 0 !important;
}
.create-event .help-icon {
  cursor: pointer;
  position: relative;
  top: 6px;
  left: 2px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 1rem;
}

.create-event .MuiTextField-root label[data-shrink="true"],
#event-type[data-shrink="true"] {
  transform: translate(14px, -12px) scale(0.75) !important;
  background-color: rgb(247, 247, 247) !important;
  padding: 0 5px;
}

.drawer,
.giftIcon {
  background-color: var(--primary) !important;
  color: white !important;
  /* transform: translateX(50%); */
}
.giftIcon {
  padding: 8px;
  border-radius: 100%;
  cursor: pointer;
}
.text-overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.viewAll button {
  background: var(--primary) !important;
  border: none;
  outline: none;
}
.heading .MuiBadge-dot {
  position: absolute;
  top: 5px;
  right: 6px;
}
.optionContainer {
  max-height: 150px !important;
  scrollbar-color: grey;
  scrollbar-width: thin;
}
.MuiDrawer-paper.MuiDrawer-paperAnchorRight {
  overflow-x: hidden !important;
}
.overflow-hide-drawer.events-drawer {
  max-height: 45%;
  /* min-height: 50%; */
  overflow-y: scroll;
  scrollbar-color: rgb(59, 59, 59);
  scrollbar-width: thin;
}
.overflow-hide-drawer.tasks-drawer {
  max-height: 45%;
  /* min-height: 50%; */
  overflow-y: scroll;
  scrollbar-color: rgb(59, 59, 59);
  scrollbar-width: thin;
}
.highlightOption {
  background: transparent !important;
  color: var(--primary) !important;
}
.optionListContainer li:hover {
  background: rgba(0, 0, 0, 0.1) !important;
  color: var(--primary) !important;
}
input.searchBox::placeholder {
  color: rgba(0, 0, 0, 1);
}
.date-joined {
  font-size: small;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 8px 0;
  margin-top: 16px;
  line-height: 0;
}
.date-joined-text {
  font-size: x-small;
}
.sub-title {
  font-size: medium;
  text-align: left;
}
@media (max-width: 767px) {
  .profile-box .name h3 {
    font-size: 1.3rem;
  }
  .profile-box .period h5 {
    font-size: 1.1rem;
  }
}
